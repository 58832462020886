import { ResponseGetAddress } from 'shared/api/personalData/types'
import { IPersonalDataAddress, TLists } from '../model/userPersonalDataSlice'

export const initialAddressValue: ResponseGetAddress = {
  Name: '',
  Guid: '',
}

const DEFAULT_ADDRESSES: Partial<IPersonalDataAddress> = {
  Region: initialAddressValue,
  District: initialAddressValue,
  City: initialAddressValue,
  Locality: initialAddressValue,
  Territory: initialAddressValue,
  Street: initialAddressValue,
  House: initialAddressValue,
  Appartament: '',
  Index: '',
}

export const getAddressStateValue = (
  state: IPersonalDataAddress,
  field: keyof IPersonalDataAddress
): IPersonalDataAddress => {
  switch (field) {
    case 'Country':
      return {
        ...state,
        ...DEFAULT_ADDRESSES,
      }

    case 'Region': {
      const endState = { ...DEFAULT_ADDRESSES }
      delete endState.Region
      return { ...state, ...endState }
    }
    case 'District': {
      const endState = { ...DEFAULT_ADDRESSES }
      delete endState.Region
      delete endState.District
      return { ...state, ...endState }
    }
    case 'City': {
      const endState = { ...DEFAULT_ADDRESSES }
      delete endState.Region
      delete endState.District
      delete endState.City
      return { ...state, ...endState }
    }
    case 'Locality': {
      const endState = { ...DEFAULT_ADDRESSES }
      delete endState.Region
      delete endState.District
      delete endState.City
      delete endState.Locality
      return { ...state, ...endState }
    }
    case 'Territory': {
      const endState = { ...DEFAULT_ADDRESSES }
      delete endState.Region
      delete endState.District
      delete endState.City
      delete endState.Locality
      delete endState.Territory
      return { ...state, ...endState }
    }
    case 'Street': {
      const endState = { ...DEFAULT_ADDRESSES }
      delete endState.Region
      delete endState.District
      delete endState.City
      delete endState.Locality
      delete endState.Territory
      delete endState.Street
      return { ...state, ...endState }
    }
    case 'House': {
      return state
    }

    default:
      return { ...state }
  }
}

export const resetLists = (state: TLists, field: keyof IPersonalDataAddress): TLists => {
  switch (field) {
    case 'Country':
      const endState = { ...state }
      endState.Region = []
      endState.District = []
      endState.City = []
      endState.Locality = []
      endState.Territory = []
      endState.Street = []
      endState.House = []
      endState.Appartament = []
      return {
        ...endState,
      }
    case 'Region': {
      const endState = { ...state }
      endState.District = []
      endState.City = []
      endState.Locality = []
      endState.Territory = []
      endState.Street = []
      endState.House = []
      endState.Appartament = []
      return { ...endState }
    }
    case 'District': {
      const endState = { ...state }
      endState.City = []
      endState.Locality = []
      endState.Territory = []
      endState.Street = []
      endState.House = []
      endState.Appartament = []
      return { ...endState }
    }
    case 'City': {
      const endState = { ...state }
      endState.Locality = []
      endState.Territory = []
      endState.Street = []
      endState.House = []
      endState.Appartament = []
      return { ...endState }
    }
    case 'Locality': {
      const endState = { ...state }
      endState.Territory = []
      endState.Street = []
      endState.House = []
      endState.Appartament = []
      return { ...endState }
    }
    case 'Territory': {
      const endState = { ...state }
      endState.Street = []
      endState.House = []
      endState.Appartament = []
      return { ...endState }
    }
    case 'Street': {
      const endState = { ...state }
      endState.House = []
      endState.Appartament = []
      return { ...endState }
    }
    case 'House': {
      const endState = { ...state }
      endState.Appartament = []
      return { ...endState }
    }

    default:
      return { ...state }
  }
}
