import { ITableHeader } from '@sf/sibgu.package.ui/dist/types/components/Table/types'
import { IOrderList } from 'shared/api/personalData/types'

export const ORDERS_LIST_TABLE_HEADERS: ITableHeader<keyof IOrderList>[] = [
  { name: '№', value: 'number' },
  { name: 'Дата', value: 'date' },
  { name: 'Наименование приказа', value: 'name' },
  // { name: '', value: 'id' },
]
export const ORDERS_LIST_COLUMN_WIDTH: Partial<Record<keyof IOrderList, number>> = {
  number: 10,
  date: 15,
  // name: 82,
}
