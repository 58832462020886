import React from 'react'
import { Skeleton } from '@sf/sibgu.package.ui'

interface PageSkeletonProps {
  title: string
  height?: number
}

export const PageSkeleton: React.FC<PageSkeletonProps> = (props) => {
  const { title, height = 200 } = props
  return (
    <div className='main-cont first-open'>
      <div className='text title'>{title}</div>

      <div className='spacer-3' />

      <Skeleton height={height} />
    </div>
  )
}
