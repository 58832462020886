import { AddressFieldsEnum, IPersonalDataAddress } from 'entities/personalData/model/userPersonalDataSlice'

export const RUSSIAN_GUID = '334430da-8fb6-11ea-d79b-ac1f6bd84a8a'

export const FILTERED_ADDRESS_ENUM = Object.entries(AddressFieldsEnum).flatMap((item) =>
  typeof item[1] === 'number' ? { field: item[0] as keyof typeof AddressFieldsEnum, level: item[1] } : []
)

export const getNextAddressLevel = (
  field: keyof typeof AddressFieldsEnum
): { field: keyof typeof AddressFieldsEnum; level: number } | null => {
  const idx = FILTERED_ADDRESS_ENUM.findIndex((item) => item.field === field)
  if (idx !== -1 && idx !== FILTERED_ADDRESS_ENUM.length - 1) {
    return FILTERED_ADDRESS_ENUM[idx + 1]
  }
  return null
}

export const getAddressListToFetch = (
  address: IPersonalDataAddress
): { Level: number; ParentGuid: string; field: keyof typeof AddressFieldsEnum }[] => {
  const list: { Level: number; ParentGuid: string; field: keyof typeof AddressFieldsEnum }[] = []
  //! Регион
  if (address.Country.Guid) {
    list.push({ Level: AddressFieldsEnum.Region, ParentGuid: address.Country.Guid, field: 'Region' })
  }

  //! Район
  if (address.Region.Guid) {
    list.push({ Level: AddressFieldsEnum.District, ParentGuid: address.Region.Guid, field: 'District' })
  }

  //! Город
  if (address.District.Guid) {
    list.push({ Level: AddressFieldsEnum.City, ParentGuid: address.District.Guid, field: 'City' })
  } else if (address.Region.Guid) {
    list.push({ Level: AddressFieldsEnum.City, ParentGuid: address.Region.Guid, field: 'City' })
  }

  //! Населенный пункт
  if (address.City.Guid) {
    list.push({ Level: AddressFieldsEnum.Locality, ParentGuid: address.City.Guid, field: 'Locality' })
  } else if (address.District.Guid) {
    list.push({ Level: AddressFieldsEnum.Locality, ParentGuid: address.District.Guid, field: 'Locality' })
  } else if (address.Region.Guid) {
    list.push({ Level: AddressFieldsEnum.Locality, ParentGuid: address.Region.Guid, field: 'Locality' })
  }

  //! Территория
  if (address.Locality.Guid) {
    list.push({ Level: AddressFieldsEnum.Territory, ParentGuid: address.Locality.Guid, field: 'Territory' })
  } else if (address.City.Guid) {
    list.push({ Level: AddressFieldsEnum.Territory, ParentGuid: address.City.Guid, field: 'Territory' })
  }

  //! Улица
  if (address.Territory.Guid) {
    list.push({ Level: AddressFieldsEnum.Street, ParentGuid: address.Territory.Guid, field: 'Street' })
  } else if (address.Locality.Guid) {
    list.push({ Level: AddressFieldsEnum.Street, ParentGuid: address.Locality.Guid, field: 'Street' })
  } else if (address.City.Guid) {
    list.push({ Level: AddressFieldsEnum.Street, ParentGuid: address.City.Guid, field: 'Street' })
  }

  //! Дом
  if (address.Territory.Guid) {
    list.push({ Level: AddressFieldsEnum.House, ParentGuid: address.Territory.Guid, field: 'House' })
  } else if (address.Street.Guid) {
    list.push({ Level: AddressFieldsEnum.House, ParentGuid: address.Street.Guid, field: 'House' })
  }

  return list
}
