import { createSlice } from '@reduxjs/toolkit';

export interface IOrderListItem {
    id: number;
    subject: string;
    date: string;
    name: string;
    link?: string;
}

export interface IOrderList {
    data: IOrderListItem[] | [];
    loading: boolean;
    error: null | string;
}

const initialState: IOrderList = {
    data: [
        //todo удалить
        {
            id: 1,
            subject: '17-5235',
            date: '14.08.2015',
            name: 'Перевод на след. курс при смене основы обучения',
            link: 'https://www.sibgu.ru/files/2021/01/01/2021-01-01_1.pdf',
        },
    ],
    loading: false,
    error: null,
};

export const orderListSlice = createSlice({
    name: 'orderListSlice',
    initialState,
    reducers: {
        clearOrderListData: (state) => {
            state.data = [];
        },
    },
    extraReducers: {},
});

export const { clearOrderListData } = orderListSlice.actions;
export default orderListSlice.reducer;
