import { createAsyncThunk } from '@reduxjs/toolkit';
import { setPassword } from '../../api/password';

export interface ISetPassword {
    token: string;
    newPassword: string;
}

export const fetchSetPassword = createAsyncThunk(
    'setPassword/fetchAll',
    async (data: ISetPassword, thunkAPI) => {
        const { token, newPassword } = data;
        try {
            const response = await setPassword(token, newPassword);
            if (response.data?.status === 400) {
                return thunkAPI.rejectWithValue(response.data?.errorMessage);
            } else {
            }
        } catch (e) {
            return thunkAPI.rejectWithValue('Не удалось изменить пароль.');
        }
    }
);
