export const getSpecialMarkName = (mark: string) => {
  switch (mark) {
    case 'target':
      return 'Целевое обучение'
    // case 'OVZ':
    //     return 'ОВЗ';
    case 'VUTS':
      return 'ВУЦ'
    default:
      return ''
  }
}
