import { ITableHeader } from '@sf/sibgu.package.ui/dist/types/components/Table/types';
import { IDiplomDiscipline } from 'shared/api/personalData/types';

export const DIPLOM_ATTESTATION_TABLE_HEADERS: ITableHeader<
    keyof IDiplomDiscipline
>[] = [
    {
        name: 'Наименование дисциплин (модулей), практик, государственной итоговой аттестации',
        value: 'name',
    },
    {
        name: 'З.Е.',
        value: 'ZETNumber',
    },

    {
        name: 'Оценка',
        value: 'score',
    },
];

export const DIPLOM_ATTESTATION_LIST_COLUMN_WIDTH: Record<
    keyof IDiplomDiscipline,
    number
> = {
    name: 50,
    ZETNumber: 10,
    score: 10,
};
