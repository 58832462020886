import { useEffect } from 'react'
import { ChoiceSpecialty } from 'features/choiceSpecialty/ui'
import { IntermediateAttestation as IntermediateAttestationData } from 'entities/intermediateAttestation'
import { SkeletonIntermediateAttestation } from 'pages/academicPerformance/intermediateAttestation/components/skeletonIntermediateAttestation'
import { useAppDispatch, useAppSelector } from 'shared/lib/hooks/redux'
import { fetchSemesterList } from 'shared/model/actionCreators/semesterList'
import './intermediateAttestation.scss'

export const IntermediateAttestation = () => {
  const { error: errorIntermediateAttestation } = useAppSelector((state) => state.intermediateAttestationReducer)
  const { loading: loadingChoiceSpecialty, error: errorChoiceSpecialty } = useAppSelector(
    (state) => state.choiceSpecialtyReducer
  )
  const { loading: loadingSemesterList, error: errorSemesterList } = useAppSelector(
    (state) => state.semesterListReducer
  )
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchSemesterList())
    // dispatch(fetchChoiceSpecialty())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loadingChoiceSpecialty || loadingSemesterList) {
    return <SkeletonIntermediateAttestation />
  }

  if (errorSemesterList || errorChoiceSpecialty || errorIntermediateAttestation) {
    return <span className='text title'>Ошибка загрузки данных</span>
  }

  return (
    <div className='main-cont'>
      <div className='text title'>Результаты промежуточной аттестации</div>

      <div className='spacer-3' />

      <ChoiceSpecialty />

      <div className='spacer-3' />

      <div className='intermediate-attestation__body'>
        <IntermediateAttestationData />
      </div>
    </div>
  )
}
