import { createSlice } from '@reduxjs/toolkit';
import { IUserDataDTO } from 'shared/api/personalData/types';
import { fetchUserData } from './thunk';

export interface IUserSlice {
    data: IUserDataDTO | null;
    loading: boolean;
}
const initialState: IUserSlice = {
    data: null,
    loading: false,
};

export const userDataSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchUserData.fulfilled, (state, action) => {
            state.data = action.payload;
            state.loading = false;
        });
        builder.addCase(fetchUserData.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchUserData.rejected, (state) => {
            state.loading = false;
        });
    },
});

export default userDataSlice.reducer;
