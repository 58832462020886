import { useCallback, useEffect, useState } from 'react'

interface UseTableHeightOptions {
  noPagination?: boolean
  contentContainerId?: string
  disableLayoutScroll?: boolean
  withTimer?: boolean
  deps?: React.DependencyList | undefined
}

const useTableHeight = (options?: UseTableHeightOptions) => {
  const [tableHeight, setTableHeight] = useState<number>(0)

  const calcTableHeight = () => {
    if (tableHeight) {
      return
    }

    const layoutContent = document.getElementsByClassName('layout__content')[0]
    const contentContainer = options?.contentContainerId
      ? document.getElementById(options.contentContainerId)
      : layoutContent.children[0]

    if (layoutContent && contentContainer) {
      const layoutPadding = 10
      // console.log('layoutContent', layoutContent)
      // console.log('layoutContent.clientHeight', layoutContent.clientHeight)
      // console.log('contentContainer', contentContainer)
      // console.log('contentContainer.clientHeight', contentContainer.clientHeight)

      const newTableHeight = layoutContent.clientHeight - contentContainer.clientHeight - layoutPadding

      setTableHeight(newTableHeight)
    }
  }

  useEffect(() => {
    let timer: NodeJS.Timeout

    if (options?.withTimer) {
      timer = setTimeout(() => {
        calcTableHeight()
      }, 300)
    } else {
      calcTableHeight()
    }

    return () => {
      clearTimeout(timer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, tableHeight])

  // useEffect(() => {
  //   const layoutContent = document.getElementsByClassName('layout__content')[0]
  //   if (options?.disableLayoutScroll) {
  //     layoutContent.classList.add('scroll-disabled')
  //   }

  //   return () => {
  //     layoutContent.classList.remove('scroll-disabled')
  //   }
  // }, [options?.disableLayoutScroll])

  return tableHeight
}

export default useTableHeight
