import { useEffect, useState } from 'react'
import { useFormik, FormikProvider, Form } from 'formik'
import React from 'react'
import { formatDate } from 'shared/utils/helpers'
import { useAppDispatch, useAppSelector } from 'shared/lib/hooks/redux'
import { PersonalData } from '../components/personalData'
import { SkeletonPersonalInformation } from '../components/SkeletonPersonalInformation'
import { personalDataThunk } from 'entities/personalData'
import { IEducationsDTO, IUserDataDTO } from 'shared/api/personalData/types'
import './personlData.scss'

export interface PersonalDataFormik extends IUserDataDTO, IEducationsDTO {
  admissionYear: string
  graduationYear: string
}

export const PersonalInformation: React.FC = () => {
  const { loading, data } = useAppSelector((state) => state.userDataReducer)
  const { currentSpecialty } = useAppSelector((state) => state.choiceSpecialtyReducer)

  const [selectedSpeciality, setSelectedSpeciality] = useState<number>(0)

  useEffect(() => {
    if (data?.educations?.length) {
      const idx = data.educations.findIndex((item) => item.speciality === currentSpecialty?.label)
      if (idx !== -1) {
        setSelectedSpeciality(idx)
      }
    }
  }, [data, currentSpecialty])

  const dispatch = useAppDispatch()
  const formik = useFormik({
    initialValues:
      {
        ...data,
        birthDate: formatDate(data?.birthDate),
        ...data?.educations.map((item) => ({
          ...item,
          contractDate: formatDate(item.contractDate),
        }))[selectedSpeciality],
        admissionYear: data?.educations[selectedSpeciality]?.educationYears.split('/')[0],
        graduationYear: data?.educations[selectedSpeciality]?.educationYears.split('/')[1],
      } || ({} as PersonalDataFormik),
    validationSchema: null,
    enableReinitialize: true,
    onSubmit: () => {},
  })

  const { values } = formik

  useEffect(() => {
    dispatch(personalDataThunk.fetchUserData())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loading) {
    return <SkeletonPersonalInformation />
  }

  return (
    <div className='main-div'>
      <div className='text title'>Личная информация</div>

      <div className='spacer-3' />

      <FormikProvider value={formik}>
        <Form className='d-flex column gap-16'>
          <PersonalData values={values as PersonalDataFormik} />
        </Form>
      </FormikProvider>
    </div>
  )
}
