import { createSlice } from '@reduxjs/toolkit';
import { ICompetence } from 'shared/api/personalData/types';
import { fetchTrainingPlanInfo } from './thunk';

export interface IUserTrainingPlanSlice {
    data: ICompetence[];
    loading: boolean;
}
const initialState: IUserTrainingPlanSlice = {
    data: [],
    loading: false,
};

export const userTrainingPlanSlice = createSlice({
    name: 'userEducationPlan',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchTrainingPlanInfo.fulfilled, (state, action) => {
            state.data = action.payload.competencies;
            state.loading = false;
        });
        builder.addCase(fetchTrainingPlanInfo.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchTrainingPlanInfo.rejected, (state) => {
            state.loading = false;
        });
    },
});

export default userTrainingPlanSlice.reducer;
