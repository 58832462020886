import { IDataSidebar } from '@sf/sibgu.package.ui/dist/types/types/sidebar'
import { ISection } from '@sf/sibgu.package.ui/dist/types/types/inputSearch'
import { TThemeTag } from '@sf/sibgu.package.ui/dist/types/components/Tag/tag'

export type DisableContent = 'contracts'

export const LIST_THEME_TAG: { [status: string]: TThemeTag } = {
  [`Подтверждено`]: 'confirmed',
  [`Отправлено`]: 'sent',
  [`Отказано`]: 'denied',
  [`Черновик`]: 'draft',
}

export const LIST_DATA_SIDEBAR = (disabled: DisableContent[]): IDataSidebar[] => [
  {
    header: 'Разделы',
    content: [
      {
        header: 'Личные данные',
        icon: 'icon-person-data-student',
        link: '/personal-data',
        disabled: false,
        content: [
          {
            title: 'Личная информация',
            link: '/personal-data/personal-info',
          },
          {
            title: 'Список приказов',
            link: '/personal-data/orders-list',
          },
          {
            title: 'Учебный план',
            link: '/personal-data/education-plan',
          },
          { title: 'Договор', link: '/personal-data/contract', disabled: disabled.includes('contracts') },
        ],
      },
      {
        header: 'Успеваемость',
        icon: 'icon-academic-performance-student',
        link: '/academic-performance',
        disabled: false,
        content: [
          {
            title: 'Результаты промежуточной аттестации',
            link: '/academic-performance/intermediate-attestation',
          },
          {
            title: 'Результаты текущего контроля',
            link: '/academic-performance/current-attestation',
          },
          {
            title: 'Приложение к диплому',
            link: '/academic-performance/diplom-application',
          },
          {
            title: 'Результаты освоения программы',
            link: '/academic-performance/results-of-programs',
          },
        ],
      },
      {
        header: 'Моё портфолио',
        icon: 'icon-my-portfolio-student',
        disabled: true,
        content: [
          { title: 'Разработка РПД', link: '' },
          { title: 'Разработка РПП', link: '' },
          { title: 'Разработка ГИА', link: '' },
          { title: 'Разработка Общей характеристики', link: '' },
          { title: 'Разработка программы воспитания', link: '' },
          { title: 'Учебные планы', link: '' },
          { title: 'Генератор РПД', link: '' },
        ],
      },
      {
        header: 'Сервисы',
        icon: 'icon-services-student',
        disabled: true,
      },
    ],
  },

  {
    header: 'Ссылки',
    content: [
      {
        header: 'Расписание',
        icon: 'icon-schedule-student',
        disabled: false,
        link: '/schedule',
        //@ts-ignore
        redirect: 'https://timetable.pallada.sibsau.ru/timetable/',
      },
      {
        header: 'Индивидуальная траектория обучения',
        icon: 'icon-individual-learning-path-student',
        disabled: true,
      },
      {
        header: 'Электронно-образовательная среда',
        icon: 'icon-e-educational-environment-student',
        link: '/electronic-educational',
        disabled: false,
        content: [
          {
            title: 'Научная библиотека',
            link: '/electronic-educational/scientific-library',
          },
          {
            title: 'Электронно-дистанционное обучение',
            link: '/electronic-educational/electronic-dist-education',
          },
          {
            title: 'Локальные нормативные акты',
            link: '/electronic-educational/local-norm-acts',
          },
          {
            title: 'РПД, ГИА, практики',
            link: '/electronic-educational/ss',
            disabled: true,
          },
          {
            title: 'Стандарт организации',
            link: '/electronic-educational/organization-standart',
          },
          {
            title: 'Дополнительное образование',
            link: '/electronic-educational/additional-education',
          },
        ],
      },

      {
        header: 'Новости / События',
        icon: 'icon-news-student',
        disabled: false,
        link: '/news-and-events',
      },
      {
        header: 'Внеучебная жизнь',
        icon: 'icon-extracurricular-life-student',
        disabled: true,
      },
      {
        header: 'Общение',
        icon: 'icon-communication-student',
        disabled: true,
      },
    ],
  },
]

export const getListToSearch = (sidebar: IDataSidebar[]): ISection[] => {
  const list: ISection[] = []
  sidebar.forEach((item) => {
    const firstLevel = item.content.filter((item) => !item.disabled)
    firstLevel.forEach((first) => {
      if (first.link && first.header) {
        list.push({ link: first.link, title: first.header })
      }
      const secondLevel = first?.content?.filter((item) => !item.disabled)
      secondLevel?.forEach(
        (second) => second.link && second.title && list.push({ title: second.title, link: second.link })
      )
    })
  })
  return list
}

export const LIST_OF_SECTIONS_TO_SEARCH: ISection[] = [
  {
    title: 'Успеваемость',
    link: '/academic-performance/intermediate-attestation',
  },
  {
    title: 'Результаты промежуточной аттестации',
    link: '/academic-performance/intermediate-attestation',
  },
  {
    title: 'Результаты текущей аттестации',
    link: '/academic-performance/current-attestation',
  },
]

export const SEMESTER_TITLE_LIST: { [key: string]: string } = {
  'Первый семестр': '1-й семестр',
  'Второй семестр': '2-й семестр',
  'Третий семестр': '3-й семестр',
  'Четвертый семестр': '4-й семестр',
  'Пятый семестр': '5-й семестр',
  'Шестой семестр': '6-й семестр',
  'Седьмой семестр': '7-й семестр',
  'Восьмой семестр': '8-й семестр',
  'Девятый семестр': '9-й семестр',
  'Десятый семестр': '10-й семестр',
  'Одиннадцатый семестр': '11-й семестр',
  'Двенадцатый семестр': '12-й семестр',
  'Тринадцатый семестр': '13-й семестр',
  'Четырнадцатый семестр': '14-й семестр',
  'Пятнадцатый семестр': '15-й семестр',
  'Шестнадцатый семестр': '16-й семестр',
}
