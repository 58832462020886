import { createSlice } from '@reduxjs/toolkit';
import { IContract } from 'shared/api/personalData/types';
import { fetchUserContracts } from './thunk';

export interface IUserContractsSlice {
    data: IContract[];
    loading: boolean;
}
const initialState: IUserContractsSlice = {
    data: [],
    loading: false,
};

export const userContractsSlice = createSlice({
    name: 'userContracts',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchUserContracts.fulfilled, (state, action) => {
            state.data = action.payload.contracts;
            state.loading = false;
        });
        builder.addCase(fetchUserContracts.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchUserContracts.rejected, (state) => {
            state.loading = false;
        });
    },
});

export default userContractsSlice.reducer;
