import { ChoiceSpecialty } from 'features/choiceSpecialty/ui'
import { useEffect, useMemo, useState } from 'react'
import { Table } from '@sf/sibgu.package.ui'
import { INTERIM_ATTESTATION_TABLE_HEADERS, WIDTH } from '../../lib/columns'
import { useAppDispatch, useAppSelector } from 'shared/lib/hooks/redux'
import { EducationPlanSkeleton } from '../EducationPlanSkeleton'
import { fetchUserTrainingPlan } from 'entities/personalData/model/thunk'
import { SemesterListTabs } from 'features/semesterListTabs'
import { getSortedTableDate } from 'shared/lib/utils/sorting'
import './educationPlan.scss'
import useTableHeight from 'shared/hooks/useTableHeight'
import clsx from 'clsx'
import { SEMESTER_TITLE_LIST } from 'shared/consts/lists'
import { getTableSkeleton } from 'shared/lib/utils/getTableSkeleton'

export const EducationPlan = () => {
  const { currentSpecialty, loading: specialityLoading } = useAppSelector((state) => state.choiceSpecialtyReducer)

  const { loading, data } = useAppSelector((state) => state.userEducationPlanReducer)

  const {
    selectedSemesterId,
    currentSemesterList,
    selectedIndex,
    loading: semesterLoading,
  } = useAppSelector((state) => state.semesterListReducer)
  const [tableHasScroll, setTableHasScroll] = useState<boolean>(false)
  const dispatch = useAppDispatch()

  const tableHeight = useTableHeight({
    withTimer: true,
  })

  const [selectedTab, setSelectedTab] = useState(0)

  const [buttonActive, setButtonActive] = useState({
    first: false,
    second: false,
  })
  const [hiddenTableTabs, setHiddenTableTabs] = useState([
    'Лек.',
    'Прак.',
    'Лаб.',
    'SRShours',
    'contacthours',
    'secondArrow',
  ])

  useEffect(() => {
    if (selectedSemesterId && currentSpecialty) {
      dispatch(
        fetchUserTrainingPlan({
          creditBookId: currentSpecialty.creditbook,
          semesterId: selectedSemesterId,
          index: selectedIndex,
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSemesterId, currentSpecialty, selectedIndex])

  useEffect(() => {
    let timer: NodeJS.Timeout
    timer = setTimeout(() => {
      const table = document.getElementsByClassName('current-attestation__body-table')[selectedTab]?.children?.[1]
      if (table?.scrollHeight > table?.clientHeight) {
        setTableHasScroll(true)
      } else {
        setTableHasScroll(false)
      }
    }, 100)

    return () => {
      clearTimeout(timer)
    }
  }, [data, tableHeight, selectedTab])

  const editTableTabs = () => {
    if (hiddenTableTabs.includes('contacthours')) {
      setHiddenTableTabs(['Лек.', 'Прак.', 'Лаб.'])
      setButtonActive({ ...buttonActive, first: true })
    } else {
      setHiddenTableTabs(['Лек.', 'Прак.', 'Лаб.', 'SRShours', 'contacthours', 'secondArrow'])
      setButtonActive({ first: false, second: false })
    }
  }
  const editSecondTabs = () => {
    if (hiddenTableTabs.includes('Лек.')) {
      setHiddenTableTabs([])
      setButtonActive({ ...buttonActive, second: true })
    } else {
      setHiddenTableTabs(['Лек.', 'Прак.', 'Лаб.'])
      setButtonActive({ ...buttonActive, second: false })
    }
  }

  const headers = useMemo(
    () =>
      INTERIM_ATTESTATION_TABLE_HEADERS({
        editSecondTabs,
        editTableTabs,
        buttonActive,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [buttonActive]
  )

  // if (semesterLoading && specialityLoading) {
  //   return <EducationPlanSkeleton />
  // }

  const tabsLists = currentSemesterList?.map((item) => ({
    key: item.id,
    title: SEMESTER_TITLE_LIST[item.label],
  }))

  const childrenTabs = () => {
    if (!data) {
      return
    }
    let children = []
    for (let i = 0; i < tabsLists?.length; i++) {
      if (loading && !data[i]) {
        children.push(getTableSkeleton(3, 6))
        continue
      }
      if (!data[i]) {
        children.push(<div className='current-attestation__body-table'></div>)
        continue
      }
      children.push(
        <Table
          bodyHeight={`${tableHeight}px`}
          dataTable={data[i]}
          headers={headers}
          columnWidth={WIDTH as any}
          sortTableData={getSortedTableDate}
          fieldsToHide={hiddenTableTabs as any}
          // loading={loading}
          className={clsx('current-attestation__body-table', tableHasScroll && 'education-plan-table--scroll')}
          fieldsWithoutSorting={['secondArrow', 'rightArrow']}
          // className={clsx(
          //   // (data[i].length === 0 ||
          //   //   headers.length === NUMBER_HEADERS_WITHOUT_FILTERS ||
          //   //   headers.length === NUMBER_HEADERS_WITH_RATING_SCORE ||
          //   //   headers.length === NUMBER_HEADERS_INTERIM_ATTESTATION) &&
          //   //   'current-attestation--table-base',
          //   // headers.length === NUMBER_HEADERS_WITHOUT_FILTERS && 'current-attestation--table-base-without-filters',
          //   'current-attestation__body-table'
          // )}
        />
      )
    }
    return children
  }

  return (
    <div
      className={
        'main-cont ' +
        (buttonActive.first && !buttonActive.second && 'first-open ') +
        (buttonActive.first && buttonActive.second && ' second-open ')
      }
    >
      <div className='text title'>Учебный план</div>

      <div className='spacer-3' />

      <ChoiceSpecialty />

      <div className='spacer-3' />
      <div className='current-attestation__body'>
        <SemesterListTabs
          className='current-attestation__body-tabs'
          children={childrenTabs()}
          onTabChange={setSelectedTab}
        />

        {/* <Table
            bodyHeight={`${tableHeight}px`}
            dataTable={data}
            fieldsToHide={hiddenTableTabs as any}
            columnWidth={WIDTH as any}
            loading={loading}
            headers={headers as any}
            className={clsx('current-attestation__body-table', tableHasScroll && 'education-plan-table--scroll')}
            sortTableData={getSortedTableDate}
            fieldsWithoutSorting={['secondArrow', 'rightArrow']}
          /> */}
        {/* </SemesterListTabs> */}
      </div>
    </div>
  )
}
