import { RoundGradeProgressBar } from 'shared/ui/selects/select/roundGradeProgressBar/roundGradeProgressBar'

export interface IBottomBlockWithInfoAboutCompetenceProps {
  compName: string
  compDonePerc: number
  dispIsDone: boolean
  disToCompEffect: number
  nameOfSubject: string
}

export const BottomBlockWithInfoAboutCompetence: React.FC<IBottomBlockWithInfoAboutCompetenceProps> = (props) => {
  const { compName, compDonePerc, dispIsDone, disToCompEffect, nameOfSubject } = props
  return (
    <div>
      <div className='text semi-bold m-w-800'>
        <p>{compName}</p>
      </div>
      <div className='spacer-2' />

      <ul className='d-flex column gap-16'>
        <li className='d-flex gap-8 al-cent'>
          <p className='text light-grey fz-14'>Компетенция освоена на </p>
          <RoundGradeProgressBar percentage={compDonePerc} strokeColor={dispIsDone ? 'green' : 'red'} />
          <p className={dispIsDone ? 'text green semi-bold fz-14' : 'text red semi-bold fz-14'}>
            {compDonePerc + '%'}{' '}
          </p>
        </li>

        <li className='d-flex al-cent gap-8'>
          <p className='text light-grey fz-14'>Дисциплина {!dispIsDone && 'не'} освоена</p>
          <div className={dispIsDone ? 'status-block_green status-block' : 'status-block_red status-block'} />
        </li>

        <li className='d-flex al-cent gap-8'>
          <p className='text light-grey fz-14'>Влияние на компетенцию дисциплины «{nameOfSubject}» </p>

          <RoundGradeProgressBar percentage={disToCompEffect} strokeColor={dispIsDone ? 'green' : 'red'} />

          <p className={dispIsDone ? 'text green semi-bold fz-14' : 'text red semi-bold fz-14'}>
            {disToCompEffect + '%'}
          </p>
        </li>
      </ul>
    </div>
  )
}
