import React from 'react'
import { Input } from '@sf/sibgu.package.ui'
import { IPersonalDataAddress, IPersonalDataForm } from 'entities/personalData/model/userPersonalDataSlice'

interface AnotherCountryAddressProps {
  form: IPersonalDataForm
  onInputChangeHandler: (field: keyof IPersonalDataAddress) => (ev: React.ChangeEvent<HTMLInputElement>) => void
}

export const AnotherCountryAddress: React.FC<AnotherCountryAddressProps> = (props) => {
  const { form, onInputChangeHandler } = props

  return (
    <div>
      <Input
        name='City'
        onChange={onInputChangeHandler('City')}
        value={form.Address.City.Name}
        label='Город / населенный пункт'
        className='contact-details__input'
      />
      <Input
        name='Street'
        onChange={onInputChangeHandler('Street')}
        value={form.Address.Street.Name}
        label='Адрес'
        className='contact-details__input'
      />
    </div>
  )
}
