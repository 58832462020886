import { RoundGradeProgressBar } from 'shared/ui/selects/select/roundGradeProgressBar/roundGradeProgressBar'
import subjectIsDoneSvg from 'shared/assets/resultOfPrograms/subjectIsDone.svg'
import subjectIsntDoneSvg from 'shared/assets/resultOfPrograms/subjectIsntDone.svg'
import { ISubject } from '../../lib/types'
export interface IBottomBlockWithInfoAboutSubjects {
  compName: string
  subjects: ISubject[]
  compDonePerc: number
}

export const BottomBlockWithInfoAboutSubjects: React.FC<IBottomBlockWithInfoAboutSubjects> = (props) => {
  const { compName, subjects, compDonePerc } = props

  return (
    // <div>
    <>
      <div className='d-flex al-cent'>
        <p className='mr-24 text fz-18 semi-bold m-w-800'>{compName}</p>
        <RoundGradeProgressBar percentage={compDonePerc} strokeColor={'green'} />
        <p className='text green fz-14 ml-4'>{compDonePerc + '%'}</p>
      </div>

      <div className='spacer-2' />

      <ul className='grid-row-2 subjects-full-info'>
        {subjects?.map((item) => (
          <li className='subjects-full-info__li d-flex al-cent gap-8'>
            <img
              src={(item.subjectDone && subjectIsDoneSvg) || subjectIsntDoneSvg}
              alt={item.subjectDone ? 'Статус предмета: сдан' : 'Статус предмета: не сдан'}
            />
            <p>{item.title}</p>
          </li>
        ))}
      </ul>
    </>
    // </div>
  )
}
