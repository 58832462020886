import React, { useState } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { Button, PasswordInput, PopperPassword } from '@sf/sibgu.package.ui';
import { formValidationPasswordCreating } from '../lib/formValidationPasswordCreating';
import { useAppDispatch, useAppSelector } from 'shared/lib/hooks/redux';
import { changeIsFirstAuthorization } from 'shared/api/login';
import { fetchSetPassword } from 'shared/model/actionCreators/setPassword';
import './passwordСreating.scss';

export const PasswordCreating = () => {
    const [isPopoverPasswordOpen, setIsPopoverPasswordOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const { loading, error } = useAppSelector(
        (state) => state.setPasswordReducer
    );
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const onOpenPopoverPassword = () => {
        setIsPopoverPasswordOpen(true);
    };

    const setPassword = () => {
        setErrorMessage('');
        if (values.newPassword === values.repeatPassword) {
            dispatch(
                fetchSetPassword({
                    token: localStorage.token,
                    newPassword: values.newPassword,
                })
            ).then(() => {
                changeIsFirstAuthorization().then(() => navigate('/'));
            });
        } else {
            setErrorMessage('Пароли не совпадают.');
        }
    };

    const goToHomePage = () => {
        navigate('/');
    };

    const formik = useFormik({
        initialValues: {
            newPassword: '',
            repeatPassword: '',
        },
        validationSchema: formValidationPasswordCreating,
        enableReinitialize: true,
        onSubmit: () => setPassword(),
    });
    const { values } = formik;

    return (
        <>
            <span className='password-creating__title text fz-24 bold center'>
                Изменение пароля
            </span>

            <FormikProvider value={formik}>
                <Form>
                    <PopperPassword
                        isPopoverOpen={isPopoverPasswordOpen}
                        value={values.newPassword}>
                        <PasswordInput
                            name='newPassword'
                            label='Новый пароль'
                            icon='password'
                            className='password-creating__input'
                            onFocus={onOpenPopoverPassword}
                        />
                    </PopperPassword>
                    <PasswordInput
                        name='repeatPassword'
                        label='Повторить новый пароль'
                        icon='password'
                        className='password-creating__input'
                    />
                    {error && (
                        <div className='password-creating__error-message'>
                            {error}
                        </div>
                    )}
                    {errorMessage && (
                        <div className='password-creating__error-message text dark-red d-flex al-cent'>
                            {errorMessage}
                        </div>
                    )}
                    <Button
                        type='submit'
                        text='Создать'
                        isLoading={loading}
                        className='password-creating__button'
                    />
                </Form>
            </FormikProvider>

            <Button
                className='text semi-bold'
                text={'Оставить пароль'}
                onClick={goToHomePage}
                theme={'text'}
            />
        </>
    );
};
