import { Skeleton } from '@sf/sibgu.package.ui';

export const SkeletonPersonalInformation = () => {
    return (
        <div className='main-div'>
            <div className='text title main-div__title'>Личная информация</div>

            <div className='spacer-3' />

            <Skeleton height={200} />
        </div>
    );
};
