import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchChoiceSpecialty } from './choiceSpecialty';

export interface ICreditBook {
    label: string;
    id: string;
    creditbook: string;
}

export interface IChoiceSpecialty {
    creditBookList: ICreditBook[];
    currentSpecialty: null | ICreditBook;
    loading: boolean;
    error: null | string;
}

const initialState: IChoiceSpecialty = {
    creditBookList: [],
    currentSpecialty: null,
    loading: false,
    error: null,
};

export const choiceSpecialtySlice = createSlice({
    name: 'choiceSpecialtySlice',
    initialState,
    reducers: {
        setCurrentSpecialty: (
            state,
            { payload: currentSpecialty }: PayloadAction<ICreditBook>
        ) => {
            state.currentSpecialty = currentSpecialty;
        },
    },
    extraReducers: {
        [fetchChoiceSpecialty.pending.type]: (state) => {
            state.loading = true;
            state.error = null;
        },
        [fetchChoiceSpecialty.fulfilled.type]: (
            state,
            action: PayloadAction<IChoiceSpecialty>
        ) => {
            state.creditBookList = action.payload.creditBookList;
            state.loading = false;
            state.error = '';
        },
        [fetchChoiceSpecialty.rejected.type]: (
            state,
            action: PayloadAction<string>
        ) => {
            state.loading = false;
            state.error = action.payload;
        },
    },
});

export const { setCurrentSpecialty } = choiceSpecialtySlice.actions;
export default choiceSpecialtySlice.reducer;
