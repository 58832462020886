import {
    AnyAction,
    combineReducers,
    configureStore,
    Reducer,
} from '@reduxjs/toolkit';
import loginReducer from 'features/auth/login/model/loginSlice';
import userReducer from 'entities/user/model/userSlice';
import { personalDataReducer } from 'entities/personalData/index';
import orderListReducer from 'entities/ordersList/model/orderListSlice';
import changePasswordReducer from 'features/auth/changePassword/model/changePasswordSlice';
import setPasswordReducer from '../model/reducers/setPasswordSlice';
import currentAttestationReducer from '../../entities/currentAttestation/modal/currentAttestationSlice';
import intermediateAttestationReducer from '../../entities/intermediateAttestation/modal/intermediateAttestationSlice';
import choiceSpecialtyReducer from '../../features/choiceSpecialty/modal/choiceSpecialtySlice';
import semesterListReducer from '../model/reducers/semesterListSlice';
import { clearStore } from '../model/actionCreators/clearStore';
import diplomAttestationFilter from '../../entities/diplomAttestation/model/diplomAttestationFilterSlice';

const appReducer = combineReducers({
    ...personalDataReducer,
    userReducer,
    loginReducer,
    changePasswordReducer,
    setPasswordReducer,
    currentAttestationReducer,
    intermediateAttestationReducer,
    orderListReducer,
    choiceSpecialtyReducer,
    semesterListReducer,
    diplomAttestationFilter,
});

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
    if (action.type === clearStore.type) {
        return appReducer(undefined, action);
    }
    return appReducer(state, action);
};

export const setupStore = () => {
    return configureStore({
        reducer: rootReducer,
    });
};

export type RootState = ReturnType<typeof appReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
