import { Table, Tabs } from '@sf/sibgu.package.ui'
import { SEMESTER_TITLE_LIST } from 'shared/consts/lists'
import {
  INTERIM_ATTESTATION_COLUMN_WIDTH,
  INTERIM_ATTESTATION_TABLE_HEADERS,
} from 'entities/intermediateAttestation/consts/intermediateAttestationLists'
import { useAppDispatch, useAppSelector } from 'shared/lib/hooks/redux'
import { useEffect, useMemo, useState } from 'react'
import { fetchIntermediateAttestation } from 'entities/intermediateAttestation/modal/intermediateAttestation'
import { ITab } from '@sf/sibgu.package.ui/dist/types/components/Tabs/tabs'
import clsx from 'clsx'
import { getTableSkeleton } from 'shared/lib/utils/getTableSkeleton'
import { getSortedTableDate } from 'shared/lib/utils/sorting'
import useStrangeTableHeight from 'shared/hooks/useStrangeTableHeight'

export const IntermediateAttestation = () => {
  const { loading, data } = useAppSelector((state) => state.intermediateAttestationReducer)
  const { currentSpecialty } = useAppSelector((state) => state.choiceSpecialtyReducer)
  const { creditBookList: semesterList } = useAppSelector((state) => state.semesterListReducer)
  const dispatch = useAppDispatch()

  const tableHeight = useStrangeTableHeight()

  const [selectedTab, setSelectedTab] = useState<number>()

  const getInterimAttestationItem = (index: number) => {
    localStorage.setItem('intermediateAttestationTab', index + '')
    if (currentSpecialty && !data[index] && tabsLists[index]) {
      dispatch(
        fetchIntermediateAttestation({
          specialityId: currentSpecialty.id,
          semesterId: tabsLists[index].key,
          creditBookId: currentSpecialty.creditbook,
          index: index,
        })
      )
    }
  }

  const tabsLists = useMemo(() => {
    if (!currentSpecialty || semesterList?.length <= 0) {
      return []
    }
    const semestersOfCurrentSpecialty = semesterList.find(
      (semesterListItem) => semesterListItem.creditbook === currentSpecialty?.creditbook
    )

    const semesters = semestersOfCurrentSpecialty ? semestersOfCurrentSpecialty?.semesterList : []

    return semesters
      .filter((item) => !item.isFuturePeriod)
      .map(
        (item): ITab => ({
          key: item.id,
          title: SEMESTER_TITLE_LIST[item.label],
        })
      )
  }, [currentSpecialty, semesterList])

  useEffect(() => {
    selectedTab != null && getInterimAttestationItem(selectedTab)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab, currentSpecialty])

  useEffect(() => {
    if (selectedTab == null) {
      const savedTab = localStorage.getItem('intermediateAttestationTab')
      if (savedTab) {
        setSelectedTab(+savedTab)
      } else if (tabsLists.length) {
        setSelectedTab(tabsLists.length - 1)
      }
    }
  }, [tabsLists, selectedTab])

  const childrenTabs = useMemo(() => {
    if (!data) {
      return
    }
    let children = []
    for (let i = 0; i < tabsLists.length; i++) {
      if (loading && !data[i]) {
        children.push(getTableSkeleton(3, 6))
        continue
      }
      if (!data[i]) {
        children.push(<></>)
        continue
      }
      children.push(
        <Table
          bodyHeight={`${tableHeight}px`}
          dataTable={data[i]}
          headers={INTERIM_ATTESTATION_TABLE_HEADERS}
          columnWidth={INTERIM_ATTESTATION_COLUMN_WIDTH}
          sortTableData={getSortedTableDate}
          className={clsx(data[i].length === 0 && 'table-base', 'intermediate-attestation__body-table')}
        />
      )
    }
    return children
  }, [data, loading, tabsLists, tableHeight])

  return (
    <Tabs
      className='intermediate-attestation__body-tabs'
      selected={selectedTab}
      items={tabsLists}
      children={childrenTabs}
      onSelect={setSelectedTab}
    />
  )
}
