import React, { useEffect } from 'react'
import { Table } from '@sf/sibgu.package.ui'
import { useAppDispatch, useAppSelector } from 'shared/lib/hooks/redux'
import { ORDERS_LIST_COLUMN_WIDTH, ORDERS_LIST_TABLE_HEADERS } from 'entities/ordersList/lib/const'
import { getSortedTableDate } from 'shared/lib/utils/sorting'
import { fetchUserOrderList } from 'entities/personalData/model/thunk'
import { SkeletonOrderList } from '../SkeletonOrderList'
import useTableHeight from 'shared/hooks/useTableHeight'
import { ChoiceSpecialty } from 'features/choiceSpecialty/ui'
import './ordersList.scss'

export const OrdersList: React.FC = () => {
  const { loading: specialityLoading, currentSpecialty } = useAppSelector((state) => state.choiceSpecialtyReducer)

  const { loading: orderListLoading, data } = useAppSelector((state) => state.userOrderListReducer)
  const dispatch = useAppDispatch()

  const tableHeight = useTableHeight({ noPagination: true })

  useEffect(() => {
    if (currentSpecialty) {
      dispatch(fetchUserOrderList(currentSpecialty.creditbook))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSpecialty])

  // if (orderListLoading || specialityLoading) {
  //   return <SkeletonOrderList />
  // }

  return (
    <div className='main-cont'>
      <div className='text title'>Список приказов</div>
      <div className='spacer-3' />

      <ChoiceSpecialty />

      <div className='spacer-3' />

      {/* {!!data.length && ( */}
      <Table
        loading={orderListLoading}
        dataTable={data}
        bodyHeight={`${tableHeight}px`}
        headers={ORDERS_LIST_TABLE_HEADERS}
        columnWidth={ORDERS_LIST_COLUMN_WIDTH}
        sortTableData={getSortedTableDate}
      />
      {/* )} */}
    </div>
  )
}
