import { FormikProvider, useFormik } from 'formik'
import { Select } from '@sf/sibgu.package.ui'
import { useEffect, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from 'shared/lib/hooks/redux'
import { fromFetchDataToSelectOption } from '../mappers/fromFetchDataToSelectOption'
import { ICreditBook, setCurrentSpecialty } from '../modal/choiceSpecialtySlice'
import { clearIntermediateAttestationData } from 'entities/intermediateAttestation/modal/intermediateAttestationSlice'
import { clearCurrentAttestationData } from 'entities/currentAttestation/modal/currentAttestationSlice'
import { fetchChoiceSpecialty } from '../modal/choiceSpecialty'

export const ChoiceSpecialty = () => {
  const { loading, creditBookList, currentSpecialty } = useAppSelector((state) => state.choiceSpecialtyReducer)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!creditBookList.length) {
      dispatch(fetchChoiceSpecialty())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creditBookList])

  useEffect(() => {
    if (!loading && creditBookList.length && !currentSpecialty) {
      dispatch(setCurrentSpecialty(creditBookList[0]))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creditBookList])

  const formik = useFormik({
    initialValues: {
      speciality: {},
    },
    enableReinitialize: true,
    onSubmit: () => {},
  })

  const { setFieldValue } = formik

  const changeCurrentSpecialty = (name: string, value: ICreditBook) => {
    setFieldValue(name, value)
    const currentCreditBookItem = creditBookList.find((item) => item.id === value.id)
    if (currentCreditBookItem) {
      dispatch(setCurrentSpecialty(currentCreditBookItem))
      dispatch(clearIntermediateAttestationData())
      dispatch(clearCurrentAttestationData())
    }
  }

  const optionsList = creditBookList.map((item) => fromFetchDataToSelectOption(item))

  return (
    <FormikProvider value={formik}>
      <Select
        label='Направление подготовки/Специальность'
        name='speciality'
        optionList={optionsList}
        chosenValue={{
          id: currentSpecialty?.id || '',
          title: currentSpecialty?.label || '',
        }}
        setFieldValue={changeCurrentSpecialty}
      />
    </FormikProvider>
  )
}
