import { Provider } from 'react-redux'
import 'dayjs/locale/ru'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers'
import 'react-toastify/dist/ReactToastify.css'
import '@sf/sibgu.package.ui/style.css'
import { Pages } from 'pages/pages'
import { ToastContainer } from 'react-toastify'
import { setupStore } from 'shared/model/store'
import { injectStore } from 'shared/api/interceptors'
import './app.scss'

const store = setupStore()
injectStore(store)

export const router = createBrowserRouter([{ path: '*', element: <Pages /> }])

export const App = () => {
  return (
    <Provider store={store}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ru'>
        <RouterProvider router={router} />
        <ToastContainer position='top-center' hideProgressBar={true} autoClose={3000} />
      </LocalizationProvider>
    </Provider>
  )
}
