import React from 'react';
import { Input, ButtonIcon } from '@sf/sibgu.package.ui';
import { formatDate } from 'shared/utils/helpers';

interface IPaidContractProps {
    date: string;
    number: string;
}

export const PaidContract: React.FC<IPaidContractProps> = (props) => {
    const { date, number } = props;

    return (
        <div>
            <div className='text subtitle contract__subtitle'>
                Договор об оказании платных образовательных услуг
            </div>

            <div className='d-flex gap-24'>
                <Input
                    name={'paidContractNumber' + number}
                    label='Номер договора'
                    disabled={true}
                    value={number}
                />
                <Input
                    name={'paidContractDate' + number}
                    label='Дата договора'
                    disabled={true}
                    value={formatDate(date)}
                />
                <ButtonIcon icon='download' tooltip='Загрузить' />
            </div>
        </div>
    );
};
