import * as Yup from 'yup';
import {
    MIN_PASSWORD_LENGTH_ERROR,
    REQUIRED_ERROR,
} from 'shared/consts/constants';

export const formValidationAuthorization = Yup.object({
    login: Yup.string().required(REQUIRED_ERROR),
    password: Yup.string()
        .min(8, MIN_PASSWORD_LENGTH_ERROR)
        .required(REQUIRED_ERROR),
});
