import { useEffect, useState } from 'react'
import { Form, Formik, FormikValues } from 'formik'
import { formValidationAuthorization } from '../lib/formValidationAuthorization'
import { ModalPasswordRecovery } from 'pages/login/components/modalPasswordRecovery'
import { Button, Input, PasswordInput } from '@sf/sibgu.package.ui'
import { useAppDispatch, useAppSelector } from 'shared/lib/hooks/redux'
import './authorization.scss'
import { fetchLogin } from '../model/login'
import { useNavigate, useLocation } from 'react-router-dom'

export const Authorization = () => {
  const [isOpenModal, setIsOpenModal] = useState(false)
  const { loading, error, isFirstAuthorization } = useAppSelector((state) => state.loginReducer)
  const dispatch = useAppDispatch()
  const location = useLocation()
  const navigate = useNavigate()

  const onOpenModal = () => {
    setIsOpenModal(true)
  }

  const onCloseModal = () => {
    setIsOpenModal(false)
  }

  useEffect(() => {
    if (localStorage.token && isFirstAuthorization) {
      navigate('/password-creating')
    } else if (localStorage.token) {
      navigate('/')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  const onSubmit = (values: FormikValues) => {
    dispatch(fetchLogin({ user: values.login, password: values.password }))
  }

  return (
    <>
      <span className='authorization__title text bold fz-24 center'>Авторизация</span>
      <Formik
        initialValues={{ login: '', password: '' }}
        validationSchema={formValidationAuthorization}
        onSubmit={(values) => onSubmit(values)}
      >
        <Form>
          <Input name='login' label='Логин' icon='person' className='authorization__input' />
          <PasswordInput name='password' label='Пароль' icon='password' className='authorization__input' />
          {error && <span className='authorization__error-message text dark-red d-flex al-cent jst-cent'>{error}</span>}
          <Button type='submit' text='Войти' isLoading={loading} className='authorization__button' />
        </Form>
      </Formik>

      {/* <a href='https://kk.sibsau.ru:8443/realms/sibsau/protocol/openid-connect/auth?response_type=code&client_id=solutionTest&scope=openid%20profile%20email&nonce=N654b36cf26afc&response_mode=query&state=ORAWpVWb4Bx4MEK&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fauthorization%2F&resource=https%3A%2F%2Fgraph.microsoft.com'>
        Авто
      </a> */}

      <Button className='text semi-bold' onClick={onOpenModal} text={'Забыли пароль?'} theme={'text'} />

      <ModalPasswordRecovery active={isOpenModal} onClose={onCloseModal} />
    </>
  )
}
