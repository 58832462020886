import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchIntermediateAttestation } from 'entities/intermediateAttestation/modal/intermediateAttestation';

export interface IIntermediateAttestationItem {
    id: number;
    subject: string;
    controlType: string;
    assessment?: string;
    zet: string;
    hours: number;
    date: string;
}

export interface IIntermediateAttestation {
    data: IIntermediateAttestationItem[][] | [];
    loading: boolean;
    error: null | string;
}

const initialState: IIntermediateAttestation = {
    data: [],
    loading: false,
    error: null,
};

export const intermediateAttestationSlice = createSlice({
    name: 'intermediateAttestationSlice',
    initialState,
    reducers: {
        clearIntermediateAttestationData: (state) => {
            state.data = []
        }
    },
    extraReducers: {
        [fetchIntermediateAttestation.pending.type]: (state) => {
            state.loading = true;
            state.error = null;
        },
        [fetchIntermediateAttestation.fulfilled.type]: (state, action: PayloadAction<IIntermediateAttestation>) => {
            // @ts-ignore
            state.data[action.payload.index] = action.payload.data.interAttestationList;
            state.loading = false;
            state.error = '';
        },
        [fetchIntermediateAttestation.rejected.type]: (state, action: PayloadAction<string>) => {
            state.loading = false;
            state.error = action.payload;
        },
    },
});

export const {clearIntermediateAttestationData} = intermediateAttestationSlice.actions
export default intermediateAttestationSlice.reducer