import 'intersection-observer'
import './select.scss'
import {
  ChangeEvent,
  FC,
  Fragment,
  MouseEvent,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import person from '../../../images/sidebar/personal-data.svg'
import clsx from 'clsx'
import { FieldHookConfig, useField } from 'formik'
import { Popover, PopoverState } from 'react-tiny-popover'
import { IOptionItem } from '@sf/sibgu.package.ui/dist/types/types/select'
import { Tag } from '@sf/sibgu.package.ui'
import { LIST_THEME_TAG } from 'shared/consts/lists'

export type TSizeOptionsHeight = 'sm' | 'md'
export type TDropdownPositionSelect = 'top' | 'bottom'

export interface ISelectProps {
  label: string
  name: string
  optionList?: IOptionItem[]
  withMultipleSelect?: boolean
  withSearch?: boolean
  searchValue?: string
  setSearchValue?(param: string): void
  withPerson?: boolean
  disabled?: boolean
  className?: string
  showAllTags?: boolean
  isStatus?: true
  setFieldValue?(name: string, value: object): void
  increaseOffset?(): void
  listValues?: any[]
  optionsHeightSize?: TSizeOptionsHeight
  required?: boolean
  setChangeDisciplines?(param: boolean): void
  dropdownPositionSelect?: TDropdownPositionSelect
}

type TagRefs = Record<number, HTMLElement | null>

export const UISelect: FC<ISelectProps & FieldHookConfig<string>> = (props) => {
  const {
    label,
    optionList,
    withMultipleSelect,
    withSearch,
    withPerson,
    disabled,
    className,
    setFieldValue,
    listValues,
    increaseOffset,
    isStatus,
    showAllTags,
    setSearchValue,
    searchValue,
    name,
    required = false,
    optionsHeightSize = 'md',
    setChangeDisciplines,
    dropdownPositionSelect = 'bottom',
  } = props

  useEffect(() => {
    if (listValues && listValues.length > 0) {
      setSelectedValues(listValues)
    } else {
      setSelectedValues([])
    }
  }, [listValues])

  const [lastElement, setLastElement] = useState<HTMLElement | null>(null)

  const observer = useRef<IntersectionObserver>()

  useEffect(() => {
    observer.current = new IntersectionObserver((entries) => {
      const first = entries[0]
      if (first.isIntersecting) {
        increaseOffset?.()
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const currentElement = lastElement
    const currentObserver = observer.current

    if (currentElement) {
      currentObserver?.observe(currentElement)
    }

    return () => {
      if (currentElement) {
        currentObserver?.unobserve(currentElement)
      }
    }
  }, [lastElement])

  const [optionsOpen, setOptionsOpen] = useState<boolean>(false)
  const [selectedValues, setSelectedValues] = useState<any[]>([])
  const [tagRefs, setTagRefs] = useState<TagRefs>({})
  const [widthHeader, setWidthHeader] = useState<number>(0)
  const [lastShownTagIndex, setLastShownTagIndex] = useState(0)

  const selectRef = useRef<HTMLDivElement>(null)
  const valuesRef = useRef<HTMLDivElement>(null)
  const dropdownList = useRef<HTMLUListElement>(null)

  let countHiddenTag = 0

  const [field, meta] = useField(name)

  const inputSearchElement = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (inputSearchElement.current) {
      inputSearchElement.current.focus()
    }
  }, [optionsOpen])

  useEffect(() => {
    const onResize = () => {
      updateSizes(selectRef?.current)
    }
    window.addEventListener('resize', onResize)
    return () => {
      window.removeEventListener('resize', onResize)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectRef.current, valuesRef.current])

  useEffect(() => {
    updateSizes(selectRef.current)
  })

  const updateSizes = (headerElement: HTMLDivElement | null) => {
    if (headerElement === null || valuesElement === null) {
      return
    }
    setWidthHeader(headerElement.clientWidth)
  }

  const chooseOptions = (option: IOptionItem) => {
    if (withMultipleSelect) {
      setSelectedValues((prevSelected) => {
        const selected = [...prevSelected]
        const index = selected.findIndex((value) => value.id === option.id)
        if (index > -1) {
          selected.splice(index, 1)
        } else {
          selected.push(option)
        }
        setFieldValue?.(field.name, selected)
        setChangeDisciplines?.(true)
        return selected
      })
    } else {
      if (selectedValues.length && option.id === selectedValues[0].id) {
        setSelectedValues([])
        setFieldValue?.(field.name, [])
      } else {
        setSelectedValues([option])
        setFieldValue?.(field.name, [option])
      }
      setOptionsOpen(false)
    }
  }

  const valuesElement = valuesRef.current
  const selectElement = selectRef.current

  const deleteSelectedValue = (tagIdsToRemove: readonly number[], event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
    setSelectedValues((currentValues) => {
      const newSelectedValues = currentValues.filter((tag) => !tagIdsToRemove.includes(tag.id))
      setFieldValue?.(field.name, newSelectedValues)
      setChangeDisciplines?.(true)
      return newSelectedValues
    })
  }

  const isSelectOverflowed = useMemo(() => {
    if (valuesElement !== null && selectElement !== null) {
      return valuesElement?.offsetWidth + 130 > selectElement?.offsetWidth
    }
    return false
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widthHeader, selectedValues, valuesElement, selectElement, tagRefs])

  const areElementsHidden = selectedValues.length !== 0 ? lastShownTagIndex !== selectedValues.length - 1 : false

  useLayoutEffect(() => {
    if (selectedValues.length === 0) {
      setLastShownTagIndex(0)
    }
    const lastTagIndex = selectedValues.length - 1
    if (valuesElement === null) {
      setLastShownTagIndex(lastTagIndex)
      return
    }
    let lastShownTagIndex = lastTagIndex
    let currentWidth = valuesElement?.offsetWidth
    const presentedElements = Object.values(tagRefs).filter((element) => element !== null)

    const hiddenElementsCount = selectedValues.length - presentedElements.length
    if (hiddenElementsCount > 0) {
      currentWidth -= 130
    }

    for (const selectedValue of [...selectedValues].reverse()) {
      if (currentWidth + 130 < widthHeader) {
        setLastShownTagIndex(lastShownTagIndex)
        return
      }
      const element = tagRefs[selectedValue.id]
      if (element !== null) {
        lastShownTagIndex = lastShownTagIndex - 1
        currentWidth = currentWidth - (element?.offsetWidth + 8)
      }
    }
  }, [widthHeader, selectedValues, valuesElement, selectElement, tagRefs, isSelectOverflowed])

  const handleTagRef = useCallback((ref: HTMLDivElement, tagId: number) => {
    setTagRefs((prevState) => {
      if (prevState[tagId] === ref) {
        return prevState
      }
      if (ref === null) {
        delete prevState[tagId]
        return prevState
      }
      return {
        ...prevState,
        [tagId]: ref,
      }
    })
  }, [])

  const renderHeaderValues = () => {
    if (selectedValues.length && withMultipleSelect) {
      const tagsToRender = showAllTags ? selectedValues : selectedValues.slice(0, lastShownTagIndex + 1)
      countHiddenTag = selectedValues.length - tagsToRender.length
      return tagsToRender.map((item) => (
        <Tag
          title={item.title}
          key={item.id}
          handleTagRef={handleTagRef}
          theme={'select'}
          tagIds={item.id}
          withClose={true}
          text={item.title}
          disabled={disabled}
          withPerson={withPerson}
          onDelete={deleteSelectedValue}
        />
      ))
    } else if (selectedValues.length && isStatus) {
      return (
        <Tag
          theme={LIST_THEME_TAG[selectedValues[0].title] ? LIST_THEME_TAG[selectedValues[0].title] : 'select'}
          text={selectedValues[0].title}
          disabled={disabled}
        />
      )
    } else if (selectedValues.length) {
      return <div title={selectedValues[0].title}>{selectedValues[0].title}</div>
    }
  }

  const searchOptions = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue?.(event.target.value)
  }

  const getClassNameListItem = useCallback(
    (id: string | number) => {
      const selected = [...selectedValues]
      const index = selected.findIndex((value) => value.id === id)
      if (index > -1) {
        return 'select__dropdown-list-item select__dropdown-list-item--active'
      } else {
        return 'select__dropdown-list-item'
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedValues, setSelectedValues]
  )

  const renderOptions = useCallback(
    (options: IOptionItem[] | undefined) => {
      if (options && options.length >= 1) {
        return options?.map((option, i) => (
          <Fragment key={i}>
            {i === options.length - 1 ? (
              <li
                ref={setLastElement}
                className={getClassNameListItem(option.id)}
                key={option.id}
                onClick={() => chooseOptions(option)}
              >
                {option.title}
              </li>
            ) : (
              <li className={getClassNameListItem(option.id)} key={option.id} onClick={() => chooseOptions(option)}>
                {option.title}
              </li>
            )}
            <div className='select__dropdown-list-divider' key={option.id + 'diver'} />
          </Fragment>
        ))
      } else {
        return <div className='select__dropdown-list-item'>Данных не найдено</div>
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [optionList, selectedValues, setSelectedValues, setFieldValue]
  )

  const renderDropdown = (popoverState: PopoverState) => {
    return (
      <div
        className={clsx('select__dropdown', popoverState.position === 'top' && 'select__dropdown-top')}
        style={{
          width: popoverState.childRect.width,
        }}
      >
        {withSearch && (
          <div className='select__dropdown-search'>
            <input
              ref={inputSearchElement}
              value={searchValue}
              onChange={searchOptions}
              className='select__dropdown-search-input'
              placeholder='Поиск...'
            />
          </div>
        )}
        <ul className={`select__dropdown-list select__dropdown-list-${optionsHeightSize}`} ref={dropdownList}>
          {renderOptions(optionList)}
        </ul>
      </div>
    )
  }

  return (
    <Popover
      isOpen={optionsOpen}
      positions={[dropdownPositionSelect]}
      align='start'
      reposition={false}
      containerStyle={{ zIndex: '100' }}
      clickOutsideCapture={true}
      onClickOutside={() => setOptionsOpen(false)}
      content={renderDropdown}
    >
      <div
        className={clsx(
          'select',
          optionsOpen && 'select--active',
          withPerson && 'select--person',
          disabled && 'select--special-disabled',
          meta.touched && meta.error && 'select--error',
          className
        )}
        ref={selectRef}
        onBlur={() => setOptionsOpen(false)}
      >
        <div className='select__header' onClick={() => !disabled && setOptionsOpen(!optionsOpen)}>
          {withPerson && ((!selectedValues.length && withMultipleSelect) || !withMultipleSelect) ? (
            <img className='select__header-icon' src={person} alt='person' />
          ) : null}
          <label
            className={
              selectedValues.length || optionsOpen
                ? 'select__header-label select__header-label--top'
                : 'select__header-label'
            }
          >
            {label}
            {required && <span className='select__header-label--required'>*</span>}
          </label>
          {withMultipleSelect ? (
            <>
              <div className='select__header-values' ref={valuesRef}>
                {renderHeaderValues()}
                {!!countHiddenTag && (
                  <Tag
                    key={'len1'}
                    theme={'select'}
                    tagIds={selectedValues.slice(lastShownTagIndex! + 1, selectedValues.length).map((tag) => tag.id)}
                    withClose={true}
                    text={`+${countHiddenTag}`}
                    className={`select__header-tag-number ${areElementsHidden && 'select__header-tag-number-active'}`}
                    disabled={disabled}
                    onDelete={deleteSelectedValue}
                  />
                )}
              </div>
              <div className='select__header-arrow' />
            </>
          ) : (
            <div className='select__header-value-text'>
              {renderHeaderValues()}
              <div className='select__header-arrow' />
            </div>
          )}
        </div>
        <div className='select__caption'>{meta.touched && meta.error ? meta.error : null}</div>
      </div>
    </Popover>
  )
}
