import { Progress } from 'antd';
export type IRoundProgressBarColor = 'red' | 'green' | 'blue';
export interface IRoundProgressBar {
    percentage: number;
    strokeColor: IRoundProgressBarColor;
    diameter?: number;
    showInfo?: boolean;
    strokeWidth?: number;
}
export const RoundGradeProgressBar: React.FC<IRoundProgressBar> = (props) => {
    const {
        percentage,
        strokeColor,
        diameter = 16,
        showInfo = false,
        strokeWidth = 18,
    } = props;
    const formatColor =
        strokeColor === 'green'
            ? '#7DC71C'
            : strokeColor === 'red'
            ? '#F47167'
            : strokeColor === 'blue'
            ? '#007ACC'
            : '';
    return (
        <Progress
            strokeLinecap='round'
            type='circle'
            gapDegree={20}
            percent={percentage}
            strokeColor={formatColor}
            trailColor={'#EAEAEB'}
            size={diameter + 2}
            showInfo={showInfo}
            strokeWidth={strokeWidth}
        />
    );
};
