import React from 'react'
import { InputWithSelects } from '@sf/sibgu.package.ui'
import { TOption } from '@sf/sibgu.package.ui/dist/types/components/InputWithSelects/inputWithSelects'
import { IPersonalDataAddress, IPersonalDataForm, TLists } from 'entities/personalData/model/userPersonalDataSlice'
import { ResponseGetAddress } from 'shared/api/personalData/types'

interface RussianAddressProps {
  form: IPersonalDataForm
  lists: TLists
  values: {
    Email: string
    Phone: string
    Address: IPersonalDataAddress
    fullName: string
  }
  onSelectAddressHandler: (field: keyof IPersonalDataAddress) => (option: TOption<ResponseGetAddress>) => void
}

export const RussianAddress: React.FC<RussianAddressProps> = (props) => {
  const { form, lists, onSelectAddressHandler, values } = props
  return (
    <div>
      {form.Address.Country && (
        <InputWithSelects
          name='Region'
          label='Регион'
          required
          content={lists?.Region || []}
          value={values.Address.Region.Name}
          onSelect={onSelectAddressHandler('Region')}
          className='contact-details__input'
        />
      )}
      {form.Address.Region.Guid && (
        <InputWithSelects
          name='District'
          label='Район'
          content={lists?.District || []}
          value={values.Address.District.Name}
          onSelect={onSelectAddressHandler('District')}
          className='contact-details__input'
        />
      )}
      {(form.Address.District.Guid || form.Address.Region.Guid) && (
        <InputWithSelects
          name='City'
          label='Город'
          content={lists?.City || []}
          value={values.Address.City.Name}
          onSelect={onSelectAddressHandler('City')}
          className='contact-details__input'
        />
      )}
      {(form.Address.District.Guid || form.Address.Region.Guid || form.Address.City) && (
        <InputWithSelects
          name='Locality'
          label='Населенный пункт'
          content={lists?.Locality || []}
          value={values.Address.Locality.Name}
          onSelect={onSelectAddressHandler('Locality')}
          className='contact-details__input'
        />
      )}
      {(form.Address.District.Guid || form.Address.Region.Guid || form.Address.City || form.Address.Locality) && (
        <InputWithSelects
          name='Territory'
          label='Территория'
          content={lists?.Territory || []}
          value={values.Address.Territory.Name}
          onSelect={onSelectAddressHandler('Territory')}
          className='contact-details__input'
        />
      )}
      {(form.Address.City.Guid || form.Address.Locality.Guid || form.Address.Region || form.Address.District) && (
        <InputWithSelects
          name='Street'
          label='Улица'
          content={lists?.Street || []}
          value={values.Address.Street.Name}
          onSelect={onSelectAddressHandler('Street')}
          className='contact-details__input'
        />
      )}
      {(form.Address.Street.Guid || form.Address.Territory) && (
        <InputWithSelects
          name='House'
          label='Дом'
          content={lists?.House || []}
          value={values.Address.House.Name}
          onSelect={onSelectAddressHandler('House')}
          className='contact-details__input'
        />
      )}
    </div>
  )
}
