import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IFilterField } from 'widgets/modalFilter/modalFilter';

export interface IFilter {
    filteredTableData: any[];
    filterFields: IFilterField[];
    numberOfNotificationsFilter: number;
}

const initialState: IFilter = {
    filteredTableData: [],
    filterFields: [],
    numberOfNotificationsFilter: 0,
};

export const diplomAttestationFilterSlice = createSlice({
    name: 'filter',
    initialState,
    reducers: {
        setFilteredTableData: (
            state,
            { payload: dataTable }: PayloadAction<any[]>
        ) => {
            state.filteredTableData = dataTable;
        },
        setFilterFields: (
            state,
            { payload: filterFields }: PayloadAction<IFilterField[]>
        ) => {
            state.filterFields = filterFields;
        },
        setNumberOfNotificationsFilter: (
            state,
            { payload: numberOfNotifications }: PayloadAction<number>
        ) => {
            state.numberOfNotificationsFilter = numberOfNotifications;
        },
        resetValuesFilter: (state) => {
            state.filteredTableData = [];
            state.filterFields = [];
            state.numberOfNotificationsFilter = 0;
        },
    },
});

export const {
    setFilteredTableData,
    setFilterFields,
    setNumberOfNotificationsFilter,
    resetValuesFilter,
} = diplomAttestationFilterSlice.actions;
export default diplomAttestationFilterSlice.reducer;
