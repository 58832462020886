import { Input } from '@sf/sibgu.package.ui'
import { IOptionItem } from '@sf/sibgu.package.ui/dist/types/types/select'
import React, { useMemo } from 'react'
import { UISelect } from 'shared/ui/selects/select/select'
import { getSpecialMarkName } from '../lib/helper'
import { PersonalDataFormik } from '../Page/Page'
import { ChoiceSpecialty } from 'features/choiceSpecialty/ui'

interface PersonalDataProps {
  values: PersonalDataFormik | null
}

export const PersonalData: React.FC<PersonalDataProps> = (props) => {
  const { values } = props

  const specialMarksList = useMemo<IOptionItem[]>(() => {
    const list = Object.entries(values?.specialMarks || {})
      .filter((item) => item[1])
      .map((item: any) => ({
        id: item[0],
        title: getSpecialMarkName(item[0]),
      }))
      .filter((item) => item.title)

    if (values?.specialMarks?.OVZList?.length) {
      values.specialMarks.OVZList.forEach((item) => list.push({ id: item, title: item }))
    }

    return list
  }, [values])

  return (
    <>
      <div className='grid-row-2 gap-24'>
        <Input name='fullName' label='ФИО' disabled={true} />
        <Input name='previousFullName' label='ФИО предыдущее' disabled={true} />
      </div>

      <div className='grid-row-2 gap-24'>
        <div className='d-flex gap-24'>
          <Input name='gender' label='Пол' disabled={true} />
          <Input name='birthDate' label='Дата рождения' disabled={true} />
        </div>
        <Input name='snils' label='СНИЛС' disabled={true} />
      </div>

      <div className='grid-row-2 gap-24'>
        <Input name='faculty' label='Институт' disabled={true} />
        <div className='d-flex gap-24'>
          <Input name='admissionYear' label='Год поступления' disabled={true} />
          <Input name='graduationYear' label='Год окончания' disabled={true} />
        </div>
      </div>

      <div className='grid-row-2 gap-24'>
        <Input name='educationForm' label='Форма обучения' disabled={true} />
        <Input name='educationLevel' label='Уровень подготовки' disabled={true} />
      </div>

      <div className='grid-row-2 gap-24'>
        <ChoiceSpecialty />

        <Input name='profile' label='Направленность (профиль)/Специализация' disabled={true} />
      </div>

      <div className='grid-row-2 gap-24'>
        <Input name='сourse' label='Курс' disabled={true} />
        <Input name='group' label='Группа' disabled={true} />
      </div>

      <div>
        <UISelect
          name='specialMarks'
          optionList={[]}
          showAllTags
          listValues={specialMarksList} //todo поменять
          withMultipleSelect={true}
          label='Особые отметки'
          className='person-data__select--disabled'
          disabled={true}
        />
      </div>

      <div>
        <Input name='basis' label='Основа обучения' disabled={true} />
      </div>

      <div className='grid-row-2 gap-24'>
        <Input name='contractNumber' label='Номер договора' disabled={true} />
        <Input name='contractDate' label='Дата договора' disabled={true} />
      </div>
    </>
  )
}
