import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCurrentAttestation } from 'shared/api/currentAttestation';

interface IFetchCurrentAttestation {
    specialityId: string;
    semesterId: string;
    creditBookId: string,
    index: number;
}

export const fetchCurrentAttestation = createAsyncThunk(
    'currentAttestation/fetchAll',
    async ({ specialityId, semesterId, creditBookId, index }: IFetchCurrentAttestation, thunkAPI) => {
        try {
            const response = await getCurrentAttestation(specialityId, semesterId, creditBookId);
            return { data: response.data, index };
        } catch (e) {
            return thunkAPI.rejectWithValue('Ошибка получения данных');
        }
    }
);