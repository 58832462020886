import { Form, FormikProvider, useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { PopperPassword, Button, PasswordInput } from '@sf/sibgu.package.ui'
import { toast } from 'react-toastify'

import { useAppDispatch, useAppSelector } from 'shared/lib/hooks/redux'
import { changeIsFirstAuthorization } from 'shared/api/login'
import { fetchChangePassword } from 'features/auth/changePassword/model/changePassword'
import { clearChangePasswordData } from 'features/auth/changePassword/model/changePasswordSlice'
import { formValidationPassword } from 'widgets/contentModalUserInfo/password/lib/formValidationPassword'
import './password.scss'

export const Password = () => {
  const [isPopoverPasswordOpen, setIsPopoverPasswordOpen] = useState(false)
  const [showFormButtons, setShowFormButtons] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const { loading, error, response } = useAppSelector((state) => state.changePasswordReducer)
  const { isFirstAuthorization } = useAppSelector((state) => state.loginReducer)
  const dispatch = useAppDispatch()

  const onOpenPopoverPassword = () => {
    setIsPopoverPasswordOpen(true)
  }

  const sendNewPassword = () => {
    setErrorMessage('')
    if (values.newPassword === values.confirmPassword) {
      dispatch(
        fetchChangePassword({
          token: localStorage.token,
          password: values.password,
          newPassword: values.newPassword,
        })
      ).then(() => {
        if (isFirstAuthorization) {
          changeIsFirstAuthorization()
        }
      })
    } else {
      setErrorMessage('Пароли не совпадают.')
    }
  }

  useEffect(() => {
    if (response) {
      toast.success(response)
      resetForm()
      setShowFormButtons(false)
      dispatch(clearChangePasswordData())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response])

  const formik = useFormik({
    initialValues: {
      password: '',
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: formValidationPassword,
    enableReinitialize: true,
    onSubmit: () => sendNewPassword(),
  })
  const { values, initialValues, resetForm } = formik

  useEffect(() => {
    if (initialValues.confirmPassword !== values.confirmPassword) {
      setShowFormButtons(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values])

  const onReset = () => {
    resetForm()
    setShowFormButtons(false)
  }

  return (
    <FormikProvider value={formik}>
      <Form className='password'>
        <div className='password__title text bold fz-24'>Пароль</div>
        <PasswordInput name='password' label='Текущий пароль' icon='password' className='password__input' />

        <div className='password__subtitle text semi-bold fz-18'>Новый пароль</div>
        <PopperPassword isPopoverOpen={isPopoverPasswordOpen} value={values.newPassword} className='password__popper'>
          <PasswordInput
            name='newPassword'
            label='Новый пароль'
            icon='password'
            className='password__input'
            onFocus={onOpenPopoverPassword}
          />
        </PopperPassword>

        <PasswordInput
          name='confirmPassword'
          label='Повторите новый пароль'
          icon='password'
          className='password__input'
        />
        {error && <div className='password__error-message d-flex al-cent text dark-red'>{error}</div>}
        {errorMessage && <div className='password__error-message'>{errorMessage}</div>}
        {showFormButtons && (
          <div className='password__group-button d-flex'>
            <Button type='reset' text='Отмена' theme='text' onClick={onReset} className='password__group-button-item' />
            <Button type='submit' isLoading={loading} text='Сохранить' className='password__group-button-item' />
          </div>
        )}
      </Form>
    </FormikProvider>
  )
}
