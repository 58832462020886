import { createSlice } from '@reduxjs/toolkit'
import { ITrainingPlan } from 'shared/api/personalData/types'
import { fetchUserTrainingPlan } from './thunk'

export interface IUserEducationPlanSlice {
  data: ITrainingPlan[][] | []
  loading: boolean
}
const initialState: IUserEducationPlanSlice = {
  data: [],
  loading: false,
}

export const userEducationPlanSlice = createSlice({
  name: 'userEducationPlan',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUserTrainingPlan.fulfilled, (state, action) => {
      state.data[action.payload.index] = action.payload.data.TrainingPlanData.map((item) => ({
        ...item,
        ...item.loadTypes.reduce((acc: { [key: string]: any }, curr) => {
          acc[curr.nameLoad] = curr.hours
          return acc
        }, {}),
      }))
      state.loading = false
    })
    builder.addCase(fetchUserTrainingPlan.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchUserTrainingPlan.rejected, (state) => {
      state.loading = false
    })
  },
})

export default userEducationPlanSlice.reducer
