import { createAsyncThunk } from '@reduxjs/toolkit';
import { getSpecialityList } from 'shared/api/lists';

export const fetchChoiceSpecialty = createAsyncThunk(
    'choiceSpecialty/fetchAll',
    async (_, thunkAPI, search?: string) => {
        try {
            const response = await getSpecialityList(search);
            return response.data;

        } catch (e) {
            return thunkAPI.rejectWithValue('Не удалось получить список специальностей.');
        }
    },
);