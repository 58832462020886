import React from 'react'
import { Input } from '@sf/sibgu.package.ui'
import { IPersonalDataAddress, IPersonalDataForm } from 'entities/personalData/model/userPersonalDataSlice'

interface EAUEAdressProps {
  form: IPersonalDataForm
  onInputChangeHandler: (field: keyof IPersonalDataAddress) => (ev: React.ChangeEvent<HTMLInputElement>) => void
}

export const EAUEAdress: React.FC<EAUEAdressProps> = (props) => {
  const { form, onInputChangeHandler } = props
  return (
    <div>
      <Input
        name='Regionы'
        onChange={onInputChangeHandler('Region')}
        value={form.Address.Region.Name}
        label='Регион'
        className='contact-details__input'
      />
      <Input
        name='District'
        onChange={onInputChangeHandler('District')}
        value={form.Address.District.Name}
        label='Район'
        className='contact-details__input'
      />
      <Input
        name='City'
        onChange={onInputChangeHandler('City')}
        value={form.Address.City.Name}
        label='Город'
        className='contact-details__input'
      />
      <Input
        name='Locality'
        onChange={onInputChangeHandler('Locality')}
        value={form.Address.Locality.Name}
        label='Населенный пункт'
        className='contact-details__input'
      />
      <Input
        name='Street'
        onChange={onInputChangeHandler('Street')}
        value={form.Address.Street.Name}
        label='Улица'
        className='contact-details__input'
      />
      <Input
        name='House'
        onChange={onInputChangeHandler('House')}
        value={form.Address.House.Name}
        label='Дом'
        className='contact-details__input'
      />
    </div>
  )
}
