import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { fetchUser } from './user'
import { fetchUpdateUserContactInfo } from 'entities/personalData/model/thunk'
import { toast } from 'react-toastify'

export interface IUser {
  fullName: string
  email: string
  loading: boolean
}
const initialState: IUser = {
  fullName: '',
  email: '',
  loading: false,
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchUser.pending.type]: (state) => {
      state.loading = true
    },
    [fetchUser.fulfilled.type]: (state, action: PayloadAction<IUser>) => {
      state.fullName = action.payload.fullName
      state.email = action.payload.email
      state.loading = false
    },
    [fetchUser.rejected.type]: (state) => {
      state.loading = false
    },
    [fetchUpdateUserContactInfo.fulfilled.type]: () => {
      toast.success('Сохранение прошло успешно!', { autoClose: 1000 })
    },
  },
})

export default userSlice.reducer
