import React from 'react'
import { Skeleton } from '@sf/sibgu.package.ui'
import { getTableSkeleton } from 'shared/lib/utils/getTableSkeleton'

export const SkeletonCurrentAttestation = () => {
  return (
    <div className='main-div'>
      <div className='text title'>Результаты текущего контроля</div>
      <Skeleton height={56} className='current-attestation__select' />
      <div className='intermediate-attestation__body'>
        <div className='intermediate-attestation__flex-for-skeletons'>
          <Skeleton height={36} />
          <Skeleton height={36} />
        </div>
        <Skeleton height={36} />
        {getTableSkeleton(3, 6)}
      </div>
    </div>
  )
}
