import { ModalUserInfo, Sidebar, Support, Header } from '@sf/sibgu.package.ui'

import { Password } from 'widgets/contentModalUserInfo/password/ui'
import { Outlet, useNavigate } from 'react-router-dom'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { DisableContent, getListToSearch, LIST_DATA_SIDEBAR } from 'shared/consts/lists'
import { clearStore } from 'shared/model/actionCreators/clearStore'
import { useAppDispatch, useAppSelector } from 'shared/lib/hooks/redux'
import { fetchUser } from 'entities/user/model/user'
import { ContactDetails } from 'widgets/contentModalUserInfo/contactDetails/ui'
import './layout.scss'
import { fetchUserContracts } from 'entities/personalData/model/thunk'

export const Layout = () => {
  const [isOpenModalProfile, setIsOpenModalProfile] = useState(false)
  const { fullName, loading } = useAppSelector((state) => state.userReducer)
  const { data } = useAppSelector((state) => state.userContractsSliceReducer)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const disabledContents = useMemo<DisableContent[]>(() => {
    const disabledList: DisableContent[] = []
    if (!data.length) {
      disabledList.push('contracts')
    }
    return disabledList
  }, [data])

  useEffect(() => {
    dispatch(fetchUser())
    dispatch(fetchUserContracts())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onCloseModalProfile = () => {
    setIsOpenModalProfile(false)
  }

  const onBackButtonClickHandler = () => {
    const button = document.getElementsByClassName('modal-info__button-back')[0]
    //@ts-ignore
    button.click()
  }

  const onOpenModalProfile = () => {
    setIsOpenModalProfile(true)
  }

  const goToPage = (link: string) => {
    navigate(link)
  }

  const getLastNameAndFirstName = useCallback(() => {
    if (fullName) {
      const arrFullName = fullName.split(' ')
      return `${arrFullName[0]} ${arrFullName[1]} ${arrFullName[2] || ''}`
    } else return ''
  }, [fullName])

  const onExitApp = () => {
    localStorage.removeItem('token')
    dispatch(clearStore())
    navigate('/authorization')
  }

  return (
    <div className='layout'>
      <Sidebar
        className='layout__sidebar'
        dataList={LIST_DATA_SIDEBAR(disabledContents)}
        pathDefaultOpenItem='Успеваемость'
      />
      <div className='layout__content-wrapper'>
        <Header
          className='layout__header'
          isLoading={loading}
          userName={getLastNameAndFirstName()}
          searchData={getListToSearch(LIST_DATA_SIDEBAR(disabledContents))}
          clickOnProfile={onOpenModalProfile}
          clickOnSection={goToPage}
        />
        <div className='layout__content'>
          <Outlet />
        </div>
      </div>
      {!loading && (
        <ModalUserInfo
          contentList={{
            contactDetails: <ContactDetails onCancel={onBackButtonClickHandler} />,
            password: <Password />,
            support: <Support />,
          }}
          userFullName={fullName}
          active={isOpenModalProfile}
          onClose={onCloseModalProfile}
          onOpen={onOpenModalProfile}
          onExitApp={onExitApp}
        />
      )}
    </div>
  )
}
