import { useEffect, useState } from 'react'

interface UseTableHeightOptions {
  noPagination?: boolean
  contentContainerId?: string
  disableLayoutScroll?: boolean
  deps?: React.DependencyList | undefined
}

const useStrangeTableHeight = (options?: UseTableHeightOptions) => {
  const [tableHeight, setTableHeight] = useState<number>(0)

  const calcTableHeight = () => {
    if (tableHeight) {
      return
    }

    const layoutContent = document.getElementsByClassName('layout__content')[0]
    const contentContainer = options?.contentContainerId
      ? document.getElementById(options.contentContainerId)
      : layoutContent.children[0]

    if (layoutContent && contentContainer) {
      const layoutPadding = 64
      const newTableHeight = layoutContent.clientHeight - contentContainer.clientHeight - layoutPadding

      setTableHeight(newTableHeight)
    }
  }

  useEffect(() => {
    let timer: NodeJS.Timeout
    timer = setTimeout(() => {
      calcTableHeight()
    }, 300)

    return () => {
      clearTimeout(timer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, tableHeight])

  return tableHeight
}

export default useStrangeTableHeight
