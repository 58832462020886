import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  confirmUserDiplomInfo,
  fetchAPIUpdateUserInfo,
  fetchAPIUserInfo,
  getAddress,
  getUserContractsData,
  getUserData,
  getUserDevelopmentTrainingPlan,
  getUserDiplomInfo,
  getUserOrderList,
  getUserTrainingPlan,
} from 'shared/api/personalData/personalData'
import { RequestFetchAPIUpdateUserInfo, RequestGetUserTrainingPlan } from 'shared/api/personalData/types'

export const fetchUserData = createAsyncThunk('user/fetchUserData', async () => {
  const response = await getUserData()
  return response.data
})

export const fetchUserOrderList = createAsyncThunk('user/fetchUserOrderList', async (creditBookId: string) => {
  const response = await getUserOrderList(creditBookId)
  return response.data
})

export const fetchUserTrainingPlan = createAsyncThunk(
  'user/fetchTrainingPlan',
  async (params: RequestGetUserTrainingPlan) => {
    const response = await getUserTrainingPlan(params)
    return { data: response.data, index: params.index }
  }
)

export const fetchUserContracts = createAsyncThunk('user/fetchContracts', async () => {
  const response = await getUserContractsData()
  return response.data
})

export const fetchUserDiplomInfo = createAsyncThunk('user/fetchDiplomInfo', async (creditBookId: string) => {
  const response = await getUserDiplomInfo(creditBookId)
  return response.data
})

export const fetchConfirmUserDiplomInfo = createAsyncThunk(
  'user/fetchConfirmDiplomInfo',
  async (guidDiplom: string) => {
    const response = await confirmUserDiplomInfo(guidDiplom)
    return response.data
  }
)

export const fetchTrainingPlanInfo = createAsyncThunk('user/fetchTrainingPlanInfo', async (creditBookId: string) => {
  const response = await getUserDevelopmentTrainingPlan(creditBookId)
  return response.data
})

export const fetchAddress = createAsyncThunk(
  'user/fetchAddress',
  async ({ Level, ParentGuid }: { Level: number; ParentGuid?: string }) => {
    if (Level !== 0 && !ParentGuid) {
      return []
    }
    const response = await getAddress(Level, ParentGuid)
    return response.data
  }
)

export const fetchUserContactInfo = createAsyncThunk('user/fetchUserContactInfo', async () => {
  const response = await fetchAPIUserInfo()
  return response.data
})

export const fetchUpdateUserContactInfo = createAsyncThunk(
  'user/fetchUpdateUserContactInfo',
  async (data: RequestFetchAPIUpdateUserInfo) => {
    return await fetchAPIUpdateUserInfo(data)
  }
)
